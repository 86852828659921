import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import CountUp from "react-countup"
import VisibilitySensor from "react-visibility-sensor"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import Layout from "../../components/layout"
import SingleButton from "../../components/singlebutton"
import { Link } from "gatsby"
import "../../scss/caseStudy.scss"

const CaseStudy = ({ data }) => {
  const templateKey = ""
  const title = "Boosting organic traffic, conversions and new customers engage Australia's GPs"
  const posttitle = "Merck Sharp & Dohme"
  const description = ""
  const onesectionheading = "The Challenge"
  const onesectiondescription1 =
    "MSD invent lifesaving therapies to treat and prevent disease, which directly or indirectly impacts the health and safety of all Australians."
  const onesectiondescription2 = "While their treatments are widely adopted, MSD wanted to increase awareness of their General Practitioner hub, which offers digital educational resources. Their website traffic needed to scale, while also being compliant with the strict Australian Medical Association advertising standards."
  const onesectiontitle = "Experience"

  const twosectiontitle = "Design"
  const twosectionheading = "Our solution"
  const twosectiondescription1 = "To raising awareness of the General Practitioner hub via organic traffic, we implemented our proven four-step SEO methodology."
  const twosectiondescription2 = "Achieving the desired results first requires defining the planned budget and setting our goals. To do this, we assessed the planned budget allocated and the expected commercial outcomes this budget is to deliver."
  const twosectiondescription3 = "Next up, we needed to define the keyword list. This involved determining the most relevant, highly searched and commercially viable phrases to target that would achieve our goals."
  const twosectiondescription4 = "To rank, we had to define what resources would be required. This meant assessing our domain against the competitor set and creating an on-page/off-page resourcing plan."
  const twosectiondescription5 = "With those three steps complete, all the was left was to execute, report and optimise. While executing the resourcing, our team was critically analysing the outcomes to determine if the activity and resourcing plan was effective. We would document our learnings and use those to optimise ongoing efforts for longterm improvements on MSDs organic search traffic to the General Practitioner hub."
  const twosectiondescription6 = "Once implemented, we were able to significantly increase awareness of the brand but also increase the amount of GPs accessing the digital content."
  const twosectiondescription7 = "The results were a 700% increase in organic traffic, a 200% increase in conversion, a 50% increase in new users and a 500% increase in new customer acquisition."

  const threesectiontitle = "Results"
  const threesectionheading1 = "700%"
  const threesectiondescription1 = "Increase In Organic Traffic"
  const threesectionheading2 = "200%"
  const threesectiondescription2 = "Increase in Conversions"
  const threesectionheading3 = "50%"
  const threesectiondescription3 = "Increase in New users"
  const threesectionheading4 = "500%"
  const threesectiondescription4 = "Increase in Customer Aquisition"

  const relatedworkheading1 = "Le Buns"
  const relatedworklink1 = "/case-studies/le-buns/"
  const relatedworkdescription1 =
    " Le Buns is an up and coming Australian swimwear label that puts sustainability and environmental consciousness at the centre of what they do."

  const relatedworkheading2 = "Sesame Street"
  const relatedworklink2 = "/case-studies/sesame-street/"
  const relatedworkdescription2 =
    " With an abundance of iconic characters instantly recognisable across multiple generations."

  const metatitle = `${posttitle} - Next&Co`
  const metadescription =
    "Next&Co are an independent SEO Agency specialising in audits, visit our site to find out your search and market trends and how to build a roadmap to success."
  const metaurl = typeof window !== "undefined" ? window.location.href : ""

  return (
    <>
      <Helmet>
        <title>{metatitle}</title>
        <meta name="description" content={metadescription} />
        <meta name="title" content={metatitle} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={metaurl} />
        <meta property="og:title" content={metatitle} />
        <meta property="og:description" content={metadescription} />
        <meta property="og:image" content="facebook.png" />
        <meta property="twitter:url" content={metaurl} />
        <meta property="twitter:title" content={metatitle} />
        <meta property="twitter:description" content={metadescription} />
        <meta property="twitter:image" content="twitter.png" />
      </Helmet>
      <Layout>
        <article className="case-study-article">
          <section id="row-one">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <div className="col-one">
                    <span className="project-name">{posttitle}</span>
                    <h1>{title}</h1>
                  </div>
                  <div className="col-two">
                    <p>{description}</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="outer-container">
            <section id="row-two">
              <div className="col-one">
                <Img fluid={data.featuredimage.childImageSharp.fluid} alt="" />
              </div>
            </section>
          </div>
          <section id="row-three">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <div className="col-one">
                    <h2 className="col-one-heading">{onesectionheading}</h2>
                    <p>{onesectiondescription1}</p>
                    <p>{onesectiondescription2}</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div id="experience-01" className="outer-container">
            <BackgroundImage
              Tag="section"
              id="row-four"
              fluid={data.onesectionbackgroundimage.childImageSharp.fluid}
              style={{
                backgroundPosition: `center`,
                backgroundSize: `cover`,
              }}
            >
              <div className="container-max-width">
                <div className="inner-container row">
                  <div className="col-one">
                    <span className="number">01</span>
                    <h2 className="col-one-heading">{onesectiontitle}</h2>
                  </div>
                  <div className="col-two">
                    <Img
                      fluid={data.onesectiontopimage.childImageSharp.fluid}
                      alt=""
                    />
                  </div>
                </div>
                <div className="inner-container">
                  <div className="col-three">
                    <Img
                      fluid={data.onesectionbottomimage.childImageSharp.fluid}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </BackgroundImage>
          </div>

          <div className="outer-container"></div>
          <section id="row-five">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <div className="row-one">
                    <span className="number">02</span>
                    <h2>{twosectiontitle}</h2>
                  </div>
                  <div className="row-two">
                    <div className="image-container">
                      <Img
                        fluid={
                          data.twosectiontopleftimage.childImageSharp.fluid
                        }
                        alt=""
                      />
                    </div>
                    <div className="image-container">
                      <Img
                        fluid={
                          data.twosectiontoprightimage.childImageSharp.fluid
                        }
                        alt=""
                      />
                    </div>
                    <div className="image-container">
                      <Img
                        fluid={
                          data.twosectionbottomleftimage.childImageSharp.fluid
                        }
                        alt=""
                      />
                    </div>
                    <div className="image-container">
                      <Img
                        fluid={
                          data.twosectionbottomrightimage.childImageSharp.fluid
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="row-six">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <div className="col-one">
                    <h2>{twosectionheading}</h2>
                    <p>{twosectiondescription1}</p>
                    <p>{twosectiondescription2}</p>
                    <p>{twosectiondescription3}</p>
                    <p>{twosectiondescription4}</p>
                    <p>{twosectiondescription5}</p>
                    <p>{twosectiondescription6}</p>
                    <p>{twosectiondescription7}</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <BackgroundImage
            Tag="section"
            id="row-seven"
            fluid={data.threesectionbackgroundimage.childImageSharp.fluid}
            style={{
              backgroundPosition: `center`,
              backgroundSize: `cover`,
            }}
          >
            <div className="container-max-width">
              <div className="inner-container">
                <div className="row-one">
                  <div className="content">
                    <h2>{threesectiontitle}</h2>
                    <span className="number">03</span>
                  </div>
                  <div className="data">
                    <div className="item">
                      <CountUp
                        start={0}
                        end={parseInt(threesectionheading1)}
                        duration={2}
                        suffix="%"
                      >
                        {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      <p>{threesectiondescription1}</p>
                    </div>
                    <div className="item">
                      <CountUp
                        start={0}
                        end={parseInt(threesectionheading2)}
                        duration={2}
                        suffix="%"
                      >
                        {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      <p>{threesectiondescription2}</p>
                    </div>
                    <div className="item">
                      <CountUp
                        start={0}
                        end={parseInt(threesectionheading3)}
                        duration={2}
                        suffix="%"
                      >
                        {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      <p>{threesectiondescription3}</p>
                    </div>
                    <div className="item">
                      <CountUp
                        start={0}
                        end={parseInt(threesectionheading4)}
                        duration={2}
                        suffix="%"
                      >
                        {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      <p>{threesectiondescription4}</p>
                    </div>
                  </div>
                </div>
                <div className="row-two">
                  <div className="image-container">
                    <Img
                      fluid={data.threesectionimage1.childImageSharp.fluid}
                      alt=""
                    />
                  </div>
                  <div className="image-container">
                    <Img
                      fluid={data.threesectionimage2.childImageSharp.fluid}
                      alt=""
                    />
                  </div>
                  <div className="image-container">
                    <Img
                      fluid={data.threesectionimage3.childImageSharp.fluid}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </BackgroundImage>

          <section id="row-contact-us">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container-contact">
                  <SingleButton buttonText="Get in touch about your project" />
                </div>
              </div>
            </div>
          </section>

          <section id="row-eight">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <div className="row-one">
                    <h2>Selected works</h2>
                  </div>
                  <div className="row-two">
                    <div className="col-one">
                      <div className="image-container">
                        <Link to={relatedworklink1} className="no-decoration">
                          <Img
                            fluid={data.relatedworkimage1.childImageSharp.fluid}
                            alt=""
                          />
                        </Link>
                      </div>
                      <div className="content">
                        <Link to={relatedworklink1} className="no-decoration">
                          <h3 className="underline-link">
                            {relatedworkheading1}
                          </h3>
                        </Link>
                        <p>{relatedworkdescription1}</p>
                      </div>
                    </div>
                    <div className="col-two">
                      <div className="image-container">
                        <Link to={relatedworklink2} className="">
                          <Img
                            fluid={data.relatedworkimage2.childImageSharp.fluid}
                            alt=""
                          />
                        </Link>
                      </div>
                      <div className="content">
                        <Link to={relatedworklink2} className="no-decoration">
                          <h3 className="underline-link">
                            {relatedworkheading2}
                          </h3>
                        </Link>
                        <p>{relatedworkdescription2}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <Careers
                 linktitle1={careers.linktitle1}
                 link1={careers.link1}
                 description1={careers.description1}
                 linktitle2={careers.linktitle2}
                 link2={careers.link2}
                 description2={careers.description2}
                 linktitle3={careers.linktitle3}
                 link3={careers.link3}
                 description3={careers.description3} /> */}
        </article>
      </Layout>
    </>
  )
}

export default CaseStudy
// search and replace for "australian-vitamins" to swap out images
export const pageQuery = graphql`
  query {
    featuredimage: file(relativePath: { eq: "case-studies/merck-sharp-and-dohme-00-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1880) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    onesectionbackgroundimage: file(
      relativePath: { eq: "case-studies/merck-sharp-and-dohme-01-background.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1880) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    onesectiontopimage: file(
      relativePath: { eq: "case-studies/merck-sharp-and-dohme-01-devices.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1880) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    onesectionbottomimage: file(
      relativePath: { eq: "case-studies/merck-sharp-and-dohme-01-ipad.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1880) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    twosectiontopleftimage: file(
      relativePath: { eq: "case-studies/merck-sharp-and-dohme-02-top-left.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    twosectiontoprightimage: file(
      relativePath: { eq: "case-studies/merck-sharp-and-dohme-02-top-right.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    twosectionbottomleftimage: file(
      relativePath: { eq: "case-studies/merck-sharp-and-dohme-02-bottom-left.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    twosectionbottomrightimage: file(
      relativePath: { eq: "case-studies/merck-sharp-and-dohme-02-bottom-right.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    threesectionbackgroundimage: file(
      relativePath: { eq: "case-studies/merck-sharp-and-dohme-03-background.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1880) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    threesectionimage1: file(
      relativePath: { eq: "case-studies/merck-sharp-and-dohme-03-01.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1880) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    threesectionimage2: file(
      relativePath: { eq: "case-studies/merck-sharp-and-dohme-03-02.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1880) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    threesectionimage3: file(
      relativePath: { eq: "case-studies/merck-sharp-and-dohme-03-03.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1880) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    relatedworkimage1: file(
      relativePath: { eq: "case-studies/le-buns-00-hero.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1880) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    relatedworkimage2: file(
      relativePath: { eq: "case-studies/sesame-street-00-hero.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1880) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
